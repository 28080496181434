body {
  margin: 0;
  font-family: "Open Sans", "Roboto", "Ubuntu", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.05rem !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace !important;
  font-size: 1rem !important;
}

.main-container {
  position: relative;
  background-color: #0d1116;

}

a {
  text-decoration: none;
  &:hover { text-decoration: underline; }
}

a:hover {
  color: #5000ca;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
}

p, a, h1, h2, h3, h4, h5, label, button {
  color: white;
}

.navigation-bar {
  justify-content: space-between;
  background-color: #0d1116;

  button {
    font-size: 1.1rem;
    font-family: "Lato", sans-serif;
    text-transform: initial;
    padding: 6px 10px;
  }

  svg {
    cursor: pointer;
  }
}

.navigation-bar-responsive {
  span, p {
    color: #0d1116;
    font-family: "Lato", sans-serif;
  }
}

.container {
  margin-top: 64px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.header-image {
  width: 100%;
  height: 450px;

  img {
    width: 100%;
    height: 450px;
    object-fit: cover;
  }
}

.mobile-menu-top {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  font-weight: bold;
}

.items-container {
  padding: 5% 10%;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

ul.dashed {
  list-style-type: '- ';
  > li {
    margin-left: -1rem;
  }
}

.light-mode {
  background-color: #f8f9fa;

  .navigation-bar {
    background-color: #f8f9fa;
  }

  p, a, h1, h2, h3, h4, h5, label, button {
    color: #0d1116;
  }

  a:hover {
    color: #5000ca;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
  }

  .navigation-bar svg {
    color: #0d1116;
  }

  .about-section {
    background-image: url("./assets/images/bg-light.png");
  }

  .skills-container svg {
    color: #0d1116;
  }

  .chip {
    background-color: rgba(0, 0, 0, 0.08);
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  }

  .vertical-timeline::before {
    background-color: rgba(0, 0, 0, 0.08);
  }

  .vertical-timeline--animate .vertical-timeline-element-content.bounce-in {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  }

  .vertical-timeline--two-columns .vertical-timeline-element-content .vertical-timeline-element-date {
    color: gray;
  }

  .vertical-timeline-element-content-arrow {
    border-right: 7px solid rgba(0, 0, 0, 0.08) !important;
  }

  .flex-chips .chip-title {
    color: #0d1116;
  }
}

@media screen {
  @media (max-width: 768px) {
    .items-container {
      padding: 5% 5%;
    }
  }
}
